"use strict";

import { ContainerModule } from 'inversify';

import { ConfigConstant, Config_Constant_DIID } from '@archipad-js/core/config';

import * as queryService from '@core/services/orm/query';

import _TodaysVisitFacade, { TodaysVisitFacade } from "@archipad/services/todays-visit.facade";
import _WorkflowUserManager, { WorkflowUserManager, WORKFLOW_COMPLEX_ROBOT_USER_TEMPLATE_ID } from "@archipad/backend/project/workflowUserManager";

import { isBugWorkflowEnabled } from '@archipad/backend/project/workflowManagerHelper';

// use generated *Entity
import { BugEntity } from "@archipad-models/models/BugEntity";
import { BugGroupEntity  } from "@archipad-models/models/BugGroupEntity";
import { BugNoteEntity  } from "@archipad-models/models/BugNoteEntity";
import { BugStateEntity  } from "@archipad-models/models/BugStateEntity";
import { BugTransitionEntity } from '@archipad-models/models/BugTransitionEntity';
import { MapEntity } from '@archipad-models/models/MapEntity';
import { PhaseEntity  } from "@archipad-models/models/PhaseEntity";
import { ProjectEntity } from "@archipad-models/models/ProjectEntity";
import { ProjectUserEntity } from '@archipad-models/models/ProjectUserEntity';
import { ProjectUserRoleEntity } from '@archipad-models/models/ProjectUserRoleEntity';
import { VisitEntity  } from "@archipad-models/models/VisitEntity";

import { WorkflowManagerError } from '@archipad/errors/errors-archipad';
import { IllegalArgumentError } from '@core/errors/errors-core';
import { flattenFilteredRelationNN, flattenRelationNN } from '@archipad/helpers/objectModelHelper';
import { Logger } from '@archipad-js/core/logger';
import { createLogger } from '@core/services/logger.service';
import _BugNoteFacade, { BugNoteFacade } from '@archipad/services/bugNote.facade';
import _PhaseManager, { PhaseManager } from './phaseManager';
import { BugGroupRelationType, BugStateAction, BugTransitionActivation, EquivBugState, EquivBugTransition, VisitTypeTemplateName } from './workflowManagerConstant';
import { WorkflowService } from '@archipad/services/workflow.service';

//=================================================================================================

export const WorkflowManagerModule = new ContainerModule(
    (bind) => {
        const workflowManagerConstants = new ConfigConstant("WorkflowManager", {
            ...EquivBugTransition,
            ...BugTransitionActivation,
            ...EquivBugState,
            ...BugStateAction,
            ...BugGroupRelationType,
        });

        bind(Config_Constant_DIID.symbol).toConstantValue(workflowManagerConstants);
    },
);

//=================================================================================================

export class WorkflowManager {

    constructor(
        private readonly workflowUserManager : WorkflowUserManager,
        private readonly phaseManager: PhaseManager,
        private readonly todaysVisitFacade : TodaysVisitFacade,
        private readonly bugNoteFacade : BugNoteFacade,
        private readonly workflowService: WorkflowService,
        private readonly logger: Logger,
    ) { }

    hasWorkflowRights(project:ProjectEntity) : boolean {
        return project && project.userRights.length > 0;
    }
    


    currentPhase(bug:BugEntity) : PhaseEntity {
        if (bug.phase) {
            return bug.phase;
        }
        return bug.visit.phase;
    }

    canEnterOpenState(visit:VisitEntity) : boolean {
        const result = this.findOpenState(visit) != null;
        return result;
    }

    enterOpenState(bug:BugEntity) : void {
        const visit = bug.visit;
        const bugState = this.findOpenState(visit);
        const user = this.workflowUserManager.getCurrentUser(visit.project);
        this._enterOpenStatePrimitive(bug, bugState, user);
    }

    private _enterOpenStatePrimitive(bug:BugEntity, bugState:BugStateEntity, user:ProjectUserEntity) {
        if (bug.notes.length != 0) {
            throw new WorkflowManagerError("Cannot enter open state : history is not empty");
        }
        if (!this.workflowService.isKindOfOpen(bugState)) {
            throw new WorkflowManagerError("Cannot enter open state : this state is not an open state");
        }
        const visit = bug.visit;
        if (!bug.phase) {
            bug.phase = this.phaseManager.phaseForBug(visit, bug.map);
        }
        bug.bugGroup = bugState.bugGroup;

        const bugNote = this.bugNoteFacade.createBugNoteModelPrimitive(bug);
        bugNote.creationDate = bug.creationDate;
        bugNote.temp = false;
        bugNote.text = null;
        bugNote.visit = visit;
        bugNote.user = user;
        bugNote.bugState = bugState;
        bugNote.bugTransition = this.workflowService.findEquivTransition(EquivBugTransition.EquivTransitionEnter, bugState, visit.project);
        bugNote.phase = bug.phase;
        // process on enter state
        this._executeEnterStateAction(bugState, bug, visit);

        return bugNote;
    }

    private _findMatchingBugState(equivStates:EquivBugState[], project:ProjectEntity, bugGroup:BugGroupEntity) {
        const entityContext = project.getContext();
        const query = queryService.query<BugStateEntity>(entityContext, "BugState");
        query.predicateWithFormat("equivBugState in {equivBugStates} AND bugGroup == {bugGroup}");
        const predicateParams = { 'equivBugStates' : equivStates, 'bugGroup' : bugGroup };

        const resultSet = query.execute(predicateParams);
        const count = resultSet.count();

        if (count != 1) {
            return null;
        }

        const bugState = resultSet.firstEntity();
        return bugState;
    }

    findLastNote(bug:BugEntity) : BugNoteEntity {
        if (bug.notes.length == 0) {
            return null;
        }
        let lastNote : BugNoteEntity = null;
        bug.notes.forEach((note) => {
            if ( note.temp ) {
                return;
            }
            if ( (lastNote == null) || (note.creationDate > lastNote.creationDate) ) {
                lastNote = note;
            }
        });
        return lastNote;
    }

    findLastNoteExcludingNote(bug:BugEntity, excludedNote:BugNoteEntity) : BugNoteEntity {
        if (bug.notes.length == 0) {
            return null;
        }
        let lastNote : BugNoteEntity = null;
        bug.notes.forEach((note) => {
            if (note == excludedNote) {
                return;
            }
            if ( note.temp ) {
                return;
            }
            if ( (lastNote == null) || (note.creationDate > lastNote.creationDate) ) {
                lastNote = note;
            }
        });
        return lastNote;
    }

    /**
     * @deprecated use `bug.stateNoteCache` instead
     */
    findLastNoteHavingState(bug:BugEntity): BugNoteEntity | null {
        if (!bug.notes || bug.notes.length == 0){
            return null;
        }
        let lastNote = null;

        for(const note of bug.notes){
            if (note.bugState == null){
                continue;
            }

            if (note.temp){
                continue;
            }

            if ((lastNote == null) || (note.creationDate > lastNote.creationDate)) {
                lastNote = note;
            }
        }
        
        return lastNote;
    }

    

    public findLastNoteHavingNamedState(bug:BugEntity, templateName:string): BugNoteEntity | null {
        if (bug.notes.length == 0) {
            return null;
        }
        let lastNote = null;
        bug.notes.forEach((note) => {
            if (note.bugState == null) {
                return;
            }
            if (templateName !== (note.bugState && note.bugState.templateName)) {
                return;
            }

            if ( (lastNote == null) || (note.creationDate > lastNote.creationDate) ) {
                lastNote = note;
            }
        });
        return lastNote;
    }

    findLastNoteHavingText(bug:BugEntity) : BugNoteEntity {
        if (bug.notes.length == 0) {
            return null;
        }

        let lastNote : BugNoteEntity = null;
        bug.notes.forEach((note) => {
            if (note.text == null) {
                return;
            }

            if (note.temp) {
                return;
            }

            // If the note has text and no lastNote or the note is more recent than the last note
            if (note.text != null && (lastNote == null || (note.creationDate > lastNote.creationDate))) {
                lastNote = note;
            }
        });

        return lastNote;
    }

    findBugState(bug:BugEntity): BugStateEntity {
        let bugState: BugStateEntity;

        const lastNote = this.findLastNoteHavingState(bug);
        if (lastNote != null) {
            bugState = lastNote.bugState;
        }
        // convert on the fly
        else {
            const project = bug.visit.project;
            if (bug.closeVisit != null) {
                bugState = this.workflowService.findEquivBugState(EquivBugState.EquivStateClosed, project);
            }
            else {
                bugState = this.workflowService.findEquivBugState(EquivBugState.EquivStateOpen, project);
            }
        }

        return bugState;
    }

    findOpenState(visit:VisitEntity) : BugStateEntity {
        // user rights checking, raw check
        const user = this.workflowUserManager.getCurrentUser(visit.project);
        if (user == null) {
            this.logger.error("[WorkflowManager] user not defined");
            return null;
        }
        else if (user.role == null) {
            this.logger.error("[WorkflowManager] user role not defined");
            return null;
        }

        // bug group from the visit type, usually null
        const visitBugGroup = visit.visitType && visit.visitType.bugGroup;
        // bug state from the visit type, usually null
        const visitBugState = visit.visitType && visit.visitType.openBugState;

        // sanity check
        if (visitBugGroup && visitBugState && visitBugGroup != visitBugState.bugGroup) {
            this.logger.error("[WorkflowManager] visit type is inconsistent");
            return null;
        }

        // find matching transitions
        const transitions = visit.project.bugWorkflow.bugTransitions;
        let enterTransitions: ReadonlyArray<BugTransitionEntity> = transitions.filter((transition) => {
            const res =
                // enter transition
                (transition.equivBugTransition == EquivBugTransition.EquivTransitionEnter)
                // no auto activation
                && (transition.autoActivation == BugTransitionActivation.TransitionActivationNone)
                // check bug group from visit type
                && ((visitBugGroup == null) || (transition.endState.bugGroup == visitBugGroup))
                // check bug state from visit type
                && ((visitBugState == null) || (transition.endState == visitBugState))
            ;

            return res;
        });

        // user rights filtering
        enterTransitions = this.filterTransitionsForRole(enterTransitions, user.role);

        let bugState : BugStateEntity;
        if (enterTransitions.length == 0) {
            bugState = null;
        }
        else if (enterTransitions.length == 1) {
            const transition = enterTransitions[0];
            bugState = transition.endState;
        }
        else {
            this.logger.debug("[WorkflowManager] multiple enter transitions found, processing best match | count:"+ enterTransitions.length);

            const lessTransitions = enterTransitions.filter((transition) => {
                const res = transition.endState.equivBugState == EquivBugState.EquivStateOpen;
                return res;
            });

            let transition = lessTransitions.length ? lessTransitions[0] : null;
            if (transition == null) {
                transition = enterTransitions[0];
            }

            bugState = transition.endState;
        }

        return bugState;
    }

    bugGroupsAllowingRead(project:ProjectEntity) : readonly BugGroupEntity[] {
        const user = this.workflowUserManager.getCurrentUser(project);

        if (user === null) {
            this.logger.error("[WorkflowManager] user not defined");
            return [];
        } else if (user.role === null) {
            this.logger.error("[WorkflowManager] user role not defined");
            return [];
        }

        if (!user.role.restrictRead) {
            return null;
        }

        return flattenFilteredRelationNN<ProjectUserRoleEntity, BugGroupEntity>(user.role, 'bugGroupsRelations', 'bugGroup', 'type', BugGroupRelationType.BugGroupAllowingRead);
    }

    bugGroupsAllowingReadNotNull(project:ProjectEntity): ReadonlyArray<BugGroupEntity> {
        const result = this.bugGroupsAllowingRead(project);
        if (result != null) {
            return result;
        }
        return project.bugWorkflow.bugGroups.concat([null]);
    }

    andBugGroupsPredicateForVisitAsString(predicate: string, visitTypePath: string, project:ProjectEntity): string {
        if (!isBugWorkflowEnabled(project)) {
            return 'true';
        }

        let path = 'bugGroup.id';
        if (visitTypePath) {
            path = `${visitTypePath}.${path}`;
        }

        const bugGroups = this.bugGroupsAllowingRead(project);

        if (bugGroups === null) {
            return predicate;
        }

        const ids = [];

        for (const bugGroup of bugGroups) {
            if (bugGroup !== null) {
                ids.push(`"${bugGroup.id}"`);
            }
        }

        return `(${predicate}) AND (${path} in ([${ids.join(', ')}]))`;
    }


    listTransitionsForBug(bug:BugEntity) : readonly BugTransitionEntity[] {
        if(!bug.visit) {
            return [];  // bug detached, about to be deleted
        }

        // user rights checking, raw check
        const user = this.workflowUserManager.getCurrentUser(bug.visit.project);
        if (user == null) {
            this.logger.error("[WorkflowManager] user not defined");
            return [];
        }
        else if (user.role == null) {
            this.logger.error("[WorkflowManager] user role not defined");
            return [];
        }

        const bugState = this.findBugState(bug);
        let result = bugState.bugTransitionStarts;

        result = result.filter((transition) => {
            // filter out transitions with auto activation
            const res = (transition.autoActivation == BugTransitionActivation.TransitionActivationNone);
            return res;
        });

        // user rights filtering
        result = this.filterTransitionsForRole(result, user.role);

        return result;
    }

    filterTransitionsForRole(transitions:ReadonlyArray<BugTransitionEntity>, role:ProjectUserRoleEntity) : readonly BugTransitionEntity[] {
        if (! role.restrictTransitions) {
            return transitions;
        }

        const roleTransitions = flattenRelationNN<ProjectUserRoleEntity, BugTransitionEntity>(role, 'transitionsRelations', 'transition');

        const result = transitions.filter((transition) => {
            const res = (roleTransitions.indexOf(transition) != -1);
            return res;
        });
        return result;
    }

    findTransition(transitions:ReadonlyArray<BugTransitionEntity>, equivTransition:EquivBugTransition) : BugTransitionEntity {
        transitions = transitions.filter((transition) => {
            return transition.equivBugTransition == equivTransition;
        });
        
        let result: BugTransitionEntity;
        if (transitions.length == 0) {
            result = null;
        }
        else if (transitions.length == 1) {
            result = transitions[0];
        }
        else {
            this.logger.debug("[WorkflowManager] multiple transitions found, processing best match | count:"+ transitions.length);

            let matchCount = 0;
            let matchAnyTransition = null;
            let matchPriority = Number.NEGATIVE_INFINITY;
            transitions.forEach((transition) => {
                let priority = transition.priority || 0;
                if (priority == 0 && (equivTransition == EquivBugTransition.EquivTransitionAccept) && this.workflowService.isKindOfClosed(transition.endState)) {
                    // priority : higher
                    priority = 1;
                }
                else if (priority == 0 && (equivTransition == EquivBugTransition.EquivTransitionReject) && this.workflowService.isKindOfOpen(transition.endState)) {
                    // priority : higher
                    priority = 1;
                }

                if (priority > matchPriority) {
                    matchCount = 1;
                    matchAnyTransition = transition;
                    matchPriority = priority;
                }
                else if (priority == matchPriority) {
                    matchCount++;
                }
            });

            if (matchCount == 1) {
                result = matchAnyTransition;
            }
            else {
                this.logger.warn("[WorkflowManager] multiple transitions found, no best match | count:"+ matchCount);
                result = null;
            }
        }
        return result;
    }

    findTransitionWithEquivState(transitions: readonly BugTransitionEntity[], equivBugState: EquivBugState) : BugTransitionEntity {
        let candidateTransitions = transitions.filter((transition) => {
            return transition.endState.equivBugState == equivBugState;
        });

        if (!candidateTransitions.length) {
            return null;
        }
        
        if (candidateTransitions.length === 1) {
            return candidateTransitions[0];
        }
        
        this.logger.debug("[WorkflowManager] multiple transitions found, processing best match | count:"+ candidateTransitions.length);
            
        candidateTransitions = candidateTransitions.sort((tA, tB) => { return tB.priority - tA.priority; });
        candidateTransitions = candidateTransitions.filter((transition) => { return transition.priority === candidateTransitions[0].priority; });
        if (candidateTransitions.length === 1) {
            return candidateTransitions[0];
        }
            
        this.logger.warn("[WorkflowManager] multiple transitions found, no best match | count:"+ candidateTransitions.length);
        return null;
    }

       findTransitionUndoingLastState(transitions:Array<BugTransitionEntity>, bug:BugEntity) : BugTransitionEntity {
        const searchBugNote = this.findLastNoteHavingState(bug);
        const searchBugState = searchBugNote && searchBugNote.bugTransition && searchBugNote.bugTransition.startState;
        if (searchBugState == null) {
            return null;
        }
        const foundTransitions = transitions.filter((transition) => {
            const res = (transition.endState.id == searchBugState.id);
            return res;
        });
        if (foundTransitions.length != 1) {
            this.logger.warn("[WorkflowManager] multiple transitions found, aborting | count:"+ foundTransitions.length);
            return null;
        }
        const result = foundTransitions[0];
        return result;
       }

       private _updateBugFromBugNote(bug:BugEntity, bugNote:BugNoteEntity) {
        const bugState = bugNote.bugState;
        if (bugState == null) {
            return;
        }

        // NOTE: maintain close visit relation
        if (this.workflowService.isKindOfClosed(bugState)) {
            bug.closeVisit = bugNote.visit;
        }
        else if (bug.closeVisit != null) {
            bug.closeVisit = null;
        }
       }

    /**
     * Execute a transition on a bug.
     */
    executeTransition(transition:BugTransitionEntity, bugNote:BugNoteEntity, bug:BugEntity, visit:VisitEntity) : BugNoteEntity {
        // NOTE : Enforce bug concrete if transition changed
        // we are making an implicit change on bug entity (it's his bugNote)
        // TODO XXX : Wanted or not ? Status change don't make bug concrete on app.
        // if(bug.temp) {makeTempBugConcrete(bug); }

        if (transition == null) {
            throw new WorkflowManagerError("Cannot execute transition : transition not defined");
        }
        if ( bugNote && bugNote.temp ) {
            throw new WorkflowManagerError("Cannot execute transition : bugNote is temp");
        }
        const currentBugState = this.findBugState(bug);

        // This is a self-defense against paper-list not disabled because current transition is selected...
        // if disabled state on ui-bug-status is buggy, it will be catched there.

        if (transition.startState !== currentBugState) {
            this.logger.error('Should not execute transition : wrong state ?', {
                current:currentBugState,
                shouldBe: transition.startState,
            });
            throw new WorkflowManagerError("Cannot execute transition : wrong state");
        }

        const bugState = transition.endState;
        bugNote = this._doTransitionToState(bugState, bugNote, bug, visit);
        bugNote.bugTransition = transition;
        // process on enter state
        this._executeEnterStateAction(bugState, bug, visit);
        // process transitions auto activation
        if (bug.relatedParentBug) {
            const iBug = bug.relatedParentBug;
            const iBugState = this.findBugState(iBug);

            iBugState.bugTransitionStarts.forEach((jTransition) => {
                if (!this._testTransitionActivation(jTransition, iBug)) {
                    return;
                }
                this.executeTransition(jTransition, null, iBug, visit);

            });
        }
        // repercussion of the parent bug transition to the child bugs
        if (bug.relatedChildBugs) {
            if (this.workflowService.isKindOfClosed(bugState)) {
                for (const childBug of bug.relatedChildBugs) {
                    const childBugState = this.findBugState(childBug);
                    const childTransition = this.findTransitionWithEquivState(childBugState.bugTransitionStarts, EquivBugState.EquivStateClosed);
                    if (!childTransition) {
                        continue; // Ignore if no transition found
                    }
                    this.executeTransition(childTransition, null, childBug, visit);
                }
            }
        }
        return bugNote;
    }

    /**
     * Execute a virtual transition without any check or side effect (on parentBug or childBug for example)
     */
    private _executeVirtualTransition(bugState: BugStateEntity, bugNote: BugNoteEntity, bug: BugEntity, visit: VisitEntity): BugNoteEntity {
        return this._doTransitionToState(bugState, bugNote, bug, visit);
    }

    private _testTransitionActivation(transition:BugTransitionEntity, bug:BugEntity) {
        let activated = false;

        const activation: BugTransitionActivation = transition.autoActivation;
        switch (activation) {
            case BugTransitionActivation.TransitionActivationNone:
            break;

            case BugTransitionActivation.TransitionActivationExplicitSystemTransition: // This transition is executed explicitly via other actions. Nothing needs to be done in this case
            break;

            case BugTransitionActivation.TransitionActivationIfEveryLinkClosed:
                activated = this._testEveryLinkClosed(bug);
            break;

            case BugTransitionActivation.TransitionActivationIfNotEveryLinkClosed:
                activated = this._testNotEveryLinkClosed(bug);
            break;

            case BugTransitionActivation.TransitionActivationEveryLinkIsSolved:
                activated = this._testEveryLinkSolved(bug);
            break;

            case BugTransitionActivation.TransitionActivationEveryLinkIsOpen:
                activated = this._testEveryLinkOpen(bug);
            break;

            default:
            break;
        }

        return activated;
    }

    private _testEveryLinkClosed(bug:BugEntity) {
        const reduceResult = bug.relatedChildBugs.every((iBug) => {
            const iResult = this.workflowService.isKindOfClosed(this.findBugState(iBug));
            return iResult;
        });
        return reduceResult;
    }

    private _testNotEveryLinkClosed(bug:BugEntity) {
        const reduceResult = bug.relatedChildBugs.some((iBug) => {
            const iResult = ! this.workflowService.isKindOfClosed(this.findBugState(iBug));
            return iResult;
        });
        return reduceResult;
    }

    private _testEveryLinkSolved(bug:BugEntity): boolean {
        const isAllSolved = bug.relatedChildBugs.every((iBug) => this.findBugState(iBug)?.templateName === "solved");
        return isAllSolved;
    }

    private _testEveryLinkOpen(bug:BugEntity): boolean {
        const isAllOpen = bug.relatedChildBugs.every((iBug) => this.findBugState(iBug)?.templateName === "open");
        return isAllOpen;
    }


    private _executeEnterStateAction(bugState:BugStateEntity, bug:BugEntity, visit:VisitEntity) {
        const action = bugState.enterAction;
        switch (action) {
            case BugStateAction.StateActionNone:
            break;

            case BugStateAction.StateActionCopyBug:
                this._createReopenRelatedBug(bug, visit);
            break;

            case BugStateAction.StateActionCleanupBugLinks:
                bug.relatedChildBugs.forEach((iBug) => {
                    if (this.workflowService.isKindOfClosed(this.findBugState(iBug))) {
                        iBug.relatedParentBug = null;
                    }
                });
            break;

            default:
            break;
        }
    }

    private _createReopenRelatedBug(bug:BugEntity, visit:VisitEntity) {
        const isAlreadyCreated = bug?.relatedChildBugs?.length;
        if (isAlreadyCreated) {
            for(const childBug of bug.relatedChildBugs) {
                const childBugState = this.findBugState(childBug);
                const iBugTransition = this.findTransitionWithEquivState(childBugState.bugTransitionStarts, EquivBugState.EquivStateOpen);
                if (!iBugTransition) {
                    continue; // Ignore if no transition found
                }
                this.executeTransition(iBugTransition, null, childBug, visit);
            }
            return;
        }


        const project = bug.visit.project;

        if (visit.visitType.eventPriority !== 1) {
            visit = this.todaysVisitFacade.getOrCreateTodaysVisit(project, VisitTypeTemplateName.HandoverToRealization);
        }

        const newBug = this._doCreateRelatedBug(bug, visit, null);
        // create bugs relation
        newBug.relatedParentBug = bug;
        return newBug;
    }

    private _createRelatedParentBug(bug:BugEntity, visit:VisitEntity, bugGroup:BugGroupEntity) {
        if (bug.relatedParentBug) {
            // nothing to do
            return null;
        }
        const newBug = this._doCreateRelatedBug(bug, visit, bugGroup);
        // create bugs relation
        bug.relatedParentBug = newBug;
        return newBug;
    }

    private _doCreateRelatedBug(bug:BugEntity, visit:VisitEntity, bugGroup:BugGroupEntity) {
        const project = bug.visit.project;

        const entityContext = bug.getContext();
        const newBug = entityContext.createEntity("Bug", null);

        // copy mandatory relations
        newBug.visit = visit;
        newBug.map = bug.map;

        const user = this.workflowUserManager.getCurrentUser(project);
        const bugState = this._findMatchingBugState(this.workflowService.kindOfOpenArray(), project, bugGroup);
        this._enterOpenStatePrimitive(newBug, bugState, user);

        // compute index
        newBug.index = 0;
        newBug.index = this._indexOfLastBugHaving(newBug.map, newBug.bugGroup);

        // copy related parent bug
        newBug.mapLocation = bug.mapLocation;
        newBug.positionX = bug.positionX;
        newBug.positionY = bug.positionY;
        newBug.location = bug.location;
        newBug.workPackage = bug.workPackage;
        newBug.text = bug.text;
        // TODO implement
        //newBug.annotations = bug.annotations;
        newBug.dueDate = bug.dueDate;

        return newBug;
    }


    private _indexOfLastBugHaving(map:MapEntity, bugGroup:BugGroupEntity) : number {
        // find the last index (note that we loop over all map bugs, including the closed ones)
        let index = 0;
        const bugs = map?.bugs ?? [];
        for(let i=0; i<bugs.length; i++) {
            const iBug = bugs[i];

            if (! (iBug.bugGroup == bugGroup) ) {
                continue;
            }

            const iIndex:number = iBug.index;
            if (index < iIndex) {
                index = iIndex;
            }
        }

        index++;
        return index;
    }

    /**
     * Make a {@link BugNoteEntity} from to perform a transition.
     */
    private _doTransitionToState(bugState: BugStateEntity, bugNote: BugNoteEntity, bug: BugEntity, visit: VisitEntity): BugNoteEntity {
        const user = this.workflowUserManager.getCurrentUser(bugState.bugWorkflow.project);
        // create new bug note entry
        if (bugNote == null) {
            bugNote = this.bugNoteFacade.createBugNoteModel(bug);
            bugNote.creationDate = new Date();
            bugNote.text = null;
            bugNote.visit = visit;
            bugNote.user = user;
        }
        bugNote.bugState = bugState;

        // update bug
        this._updateBugFromBugNote(bug, bugNote);
        return bugNote;
    }

    sendBugsToBugGroup(project:ProjectEntity, bugs:Array<BugEntity>, visitTypeTemplateName:VisitTypeTemplateName) : Array<BugEntity> {
        const visit = this.todaysVisitFacade.getOrCreateTodaysVisit(project, visitTypeTemplateName);

        const visitType = project.visitTypes.find((visitType) => visitType.templateName === visitTypeTemplateName);

        if (!visitType) {
            throw new IllegalArgumentError(`Unable to find the VisitType ${visitTypeTemplateName}.`);
        }

        const bugGroup = visitType.bugGroup;

        return bugs.map(bug => {
            return this._createRelatedParentBug(bug, visit, bugGroup);
        }).filter(bug => !!bug);
    }


    listPhasesForBugGroup(bugGroup:BugGroupEntity, project:ProjectEntity): Array<PhaseEntity> {
        let ret;

        const entityContext = project.getContext();

        // try filter
        {
            const query = queryService.query(entityContext, "Phase_BugGroups");
            query.predicateWithFormat("bugGroup == {bugGroup}");
            query.orderBy('phase.index');
            const predicateParams = { 'bugGroup' : bugGroup };

            const resultSet = query.execute(predicateParams);
            ret = resultSet.sortedEntities();
        }
        if (ret.length) {
            ret = ret.map(relation => relation.phase);
        }
        // no filter
        else {
            const query = queryService.query(entityContext, "Phase");
            query.orderBy('index');

            const resultSet = query.execute({});
            ret = resultSet.sortedEntities();
        }

        return ret;
    }


    //=================================================================================================
    // Related to : Controller logic

    reuseBugNoteForTransition(bug:BugEntity, visit:VisitEntity) : BugNoteEntity {
        // try reuse
        let bugNote = this.findLastNote(bug);
        // check exists
        if (bugNote == null) {
            // nothing to do
        }
        // check visit
        else if (bugNote.visit != visit) {
            bugNote = null;
        }
        // check user
        else if (!this.workflowUserManager.isCurrentUser(bugNote.user)) {
            bugNote = null;
        }
        // check state
        else if (bugNote.bugState != null) {
            bugNote = null;
        }
        // check phase
        else if (bugNote.phase != null) {
            bugNote = null;
        }

        if (bugNote == null) {
            bugNote = this.bugNoteFacade.createBugNote(bug, visit);
        }

        return bugNote;
    }

    newTempBugNote(bug:BugEntity, visit:VisitEntity) : BugNoteEntity {
        this.cleanTempNotes(bug);
        const bugNote = this.bugNoteFacade.createBugNote(bug, visit);
        bugNote.temp = true;

        return bugNote;
    }

    cleanTempNotes(bug:BugEntity) : void {
        if ( ! bug || !bug.notes ) {
            return;
        }

        bug.notes.forEach((note) => {
            if ( note.temp ) {
                note.getContext().deleteEntity(note);
            }
        });
    }


    flattenLastBugNote(bug:BugEntity) : boolean {
        const bugNote = this.findLastNote(bug);
        if (bugNote == null) {
            return false;
        }
        const bugNote2 = this.findLastNoteExcludingNote(bug, bugNote);
        if (bugNote2 == null) {
            return false;
        }

        // check current user
        if (!this.workflowUserManager.isCurrentUser(bugNote.user)) {
            return false;
        }
        // check visit
        if (bugNote.visit != bugNote2.visit) {
            return false;
        }
        // check user
        if (bugNote.user != bugNote2.user) {
            return false;
        }

        // check if any phase modification
        if (bugNote.phase != null || bugNote2.phase != null) {
            return false;
        }
        // check state : can the 2 states be compacted ?
        if (bugNote.bugState != null || bugNote2.bugState == null) {
            return false;
        }
        // check is creation
        if (bugNote2.bugTransition != null && bugNote2.bugTransition.equivBugTransition == EquivBugTransition.EquivTransitionEnter) {
            return false;
        }
        // check comment
        if (bugNote2.text != null && bugNote2.text.length != 0) {
            return false;
        }
        // check annotations
        if (bugNote2.hasAttachments()) {
            return false;
        }

        // merge comment
        bugNote.creationDate = bugNote2.creationDate;
        bugNote.bugState = bugNote2.bugState;
        bugNote.bugTransition = bugNote2.bugTransition;

        // delete from history
        const entityContext = bugNote2.getContext();
        entityContext.deleteEntity(bugNote2);

        return true;
    }

    findBugNoteToUndo(bug:BugEntity, visit:VisitEntity): BugNoteEntity {
        let bugNote = this.findLastNoteHavingState(bug);
        // check exists
        if (bugNote == null) {
            // nothing to do
        }
        // check state
        else if (bugNote.bugState == null) {
            bugNote = null;
        }
        // check visit
        else if (bugNote.visit != visit) {
            bugNote = null;
        }
        // check user
        else if (!this.workflowUserManager.isCurrentUser(bugNote.user)) {
            bugNote = null;
        }
        // check not initial note
        else if (bugNote.bugTransition != null && bugNote.bugTransition.equivBugTransition == EquivBugTransition.EquivTransitionEnter) {
            bugNote = null;
        }
        else if (bugNote.isPersisted()) {
            bugNote = null;
        }

        return bugNote;
    }

    undoBugNote(bug:BugEntity, visit:VisitEntity) : boolean {
        const bugNote = this.findBugNoteToUndo(bug, visit);
        // check exists
        if (bugNote == null) {
            return false;
        }

        const undoPropagationRequired = this.workflowService.isKindOfClosed(bugNote.bugState);

        // delete from history
        const entityContext = bugNote.getContext();
        entityContext.deleteEntity(bugNote);

        // update bug
        const refBugNote = this.findLastNoteHavingState(bug);
        this._updateBugFromBugNote(bug, refBugNote);

        // Propagate to child bugs
        if (undoPropagationRequired){
            for (const childBug of bug.relatedChildBugs) {
                this.undoBugNote(childBug, visit);
            }
        }

        return true;
    }

    autoAcceptBugsForProject(project: ProjectEntity): void {
        if (!project.settingAutoAcceptanceOfBug){
            return;
        }
        const currentUser = this.workflowUserManager.getCurrentUser(project);

        const bugsToValidate = this.workflowService.bugsToValidateForProject(project);
        if (!bugsToValidate || bugsToValidate.length == 0){ // No bugs in today visit
            return;
        }
        for (const bug of bugsToValidate) {
            if (bug.temp) { // Temp bug, ignore.
                continue;
            }
            const lastBugNoteHavingState = bug.stateNoteCache;
            if (!lastBugNoteHavingState) { // No state for this bug, ignore.
                continue;
            }
            if (lastBugNoteHavingState.user == null || lastBugNoteHavingState.user !== currentUser) { // Not the current user, ignore.
                continue;
            }
            let autoTransitions = this.fetchTransitionByActivation(project, BugTransitionActivation.TransitionActivationIfAutoAcceptanceOfBug);
            autoTransitions = autoTransitions.filter(transition => transition.startState === lastBugNoteHavingState.bugState);
            if (!autoTransitions || autoTransitions.length == 0) { // No transition for this bug, ignore.
                continue;
            }
            if (autoTransitions.length > 1) { // More than one transition for this bug, ignore.
                this.logger.warn(`multiple auto accept transitions found`);
                continue;
            }
            const acceptTransition = autoTransitions[0];
            // Execute transition
            const todaysVisit = this.todaysVisitFacade.getOrCreateTodaysVisit(project, VisitTypeTemplateName.HandoverToRealization );
            const projectUserRobot: ProjectUserEntity | null = this.workflowUserManager.fetchProjectUserByTemplateId(project, WORKFLOW_COMPLEX_ROBOT_USER_TEMPLATE_ID);
            const bugNote = this.bugNoteFacade.createBugNote(bug, todaysVisit);
            bugNote.text = l('Automatically validated');
            bugNote.user = projectUserRobot;
            this.executeTransition(acceptTransition, bugNote, bug, todaysVisit);
        }
    }

    fetchTransitionByActivation(project: ProjectEntity, activation: BugTransitionActivation): BugTransitionEntity[] {
        const entityContext = project.getContext();
        const query = queryService.query(entityContext, "BugTransition");
        query.predicateWithFormat("autoActivation == {bugTransitionActivation}", 
        { 'bugTransitionActivation': activation });
        query.orderBy('name desc');
        const transitions: BugTransitionEntity[] = query.execute().sortedEntities();
        return transitions;
    }

    /**
     * Delete the given bug and trigger side effects on linked bugs
     */
    deleteBug(bug: BugEntity): void {
        const entityContext = bug.getContext();
        const isBugHasParent = bug.relatedParentBug != null;
        if (isBugHasParent) {
            const parentBug = bug.relatedParentBug;
            const project = parentBug.visit.project;
            const bugStateClosedRejected = this.workflowService.findEquivBugState(EquivBugState.EquivStateClosedRejected, project);
            const todaysVisit = this.todaysVisitFacade.getOrCreateTodaysVisit(project, VisitTypeTemplateName.HandoverToRealization );
            this._executeVirtualTransition(bugStateClosedRejected, null, parentBug, todaysVisit);
        }
        entityContext.deleteEntity(bug);
    }

}


// TODO @deprecated, use DI instead
export default new WorkflowManager(_WorkflowUserManager, _PhaseManager, _TodaysVisitFacade, _BugNoteFacade, new WorkflowService(), createLogger('WorkflowManager'));
