import { z } from "zod";

import {
    CreateEntityPayload,
    createEntityWithProperties,
    updateEntityWithProperties,
} from "@core/services/orm/orm.helper";
import { Query, query } from "@core/services/orm/query";
import { BugEntity } from "@archipad-models/models/BugEntity";
import { BugGroupEntity } from "@archipad-models/models/BugGroupEntity";
import { BugNoteEntity } from "@archipad-models/models/BugNoteEntity";
import { MapEntity } from "@archipad-models/models/MapEntity";
import { PhaseEntity } from "@archipad-models/models/PhaseEntity";
import { ProjectEntity } from "@archipad-models/models/ProjectEntity";
import { VisitEntity } from "@archipad-models/models/VisitEntity";
import { formatTextList } from "@core/helpers/textHelper";
import { createLogger } from "@core/services/logger.service";
import { Logger } from "@archipad-js/core/logger";
import { BugTransitionActivation } from "@archipad/backend/project/workflowManagerConstant";
import { BugStateEntity } from "@archipad-models/models/BugStateEntity";
import { BugTransitionEntity } from "@archipad-models/models/BugTransitionEntity";

/**
 * Data access service for observations.
 */
export class BugService {

    constructor(
        protected readonly logger: Logger = createLogger("BugService"),
    ){}
    
    //#region Bug

    public createBug(plainPayload: CreateBugPayload): BugEntity {
        const project = plainPayload.map.project;
        const entityContext = project.getContext();

        const payload = BugSchema.parse(plainPayload);
        const bug = createEntityWithProperties(entityContext, "Bug", payload);
        return bug;
    }

    public listBugs(options: {
        where: BugWhereInput;
        orderBy?: string;
    }): readonly BugEntity[] {
        const defaultOrderBy = "index asc";
        const { where, orderBy = defaultOrderBy } = options;

        const q = this.makeBugQuery({ where, orderBy });

        const resultSet = q.execute();
        const bugs = resultSet.sortedEntities();
        return bugs;
    }

    public findBug(options: { where: BugWhereInput; orderBy?: string }): BugEntity | undefined {
        const { where, orderBy } = options;
        const q = this.makeBugQuery({ where, orderBy });

        const resultSet = q.execute();
        const lastBug = resultSet.firstEntity();

        return lastBug ?? undefined;
    }

    /**
     * Returns a reference bug of the given visit's bug group.
     * 
     * A reference bug is a bug that can be used to copy some properties from it
     * like phase.
     * 
     * If the map belongs to a real region it will try to find a bug in the same region.
     * If the map belongs to the unassigned region it will try to find a bug in the same map.
     */
    public findReferenceBug(options: { map: MapEntity; bugGroup?: BugGroupEntity | null }): BugEntity | undefined {
        const { map, bugGroup } = options;

        const findBugWhereInput: BugWhereInput = {
            project: map.project,
            bugGroups: [bugGroup],
        };

        const isRealRegion = map.region !== null;
        if (isRealRegion) {
            findBugWhereInput.maps = [...map.region.maps];
        } else {
            findBugWhereInput.maps = [map];
        }

        const bug = this.findBug({
            where: findBugWhereInput,
            orderBy: "index desc, creationDate desc",
        });

        return bug;
    }

    /**
     * Returns each bug with its reference bug.
     * Identic to {@link findReferenceBug} but for a list of bugs. And referenceBug can be null or equals to the bug.
     * Due to performance issues of {@link findReferenceBug}, this method should be when we have to process a large amount of bugs.
     * @see {@link https://bigsool-archipad.atlassian.net/browse/AP-10006 | AP-10006}
     * @returns [bug, referenceBug]
     */
    public findAllReferenceBugs(bugs: readonly BugEntity[]): [BugEntity, BugEntity | undefined][] {
        const CONST_UNASSIGNED_ID = "unassigned";
        if (bugs.length === 0) {
            return [];
        }
        const project = bugs[0].visit?.project;
        const bugGroups = bugs?.map(bug => bug.bugGroup);
        
        // First we fetch all bugs ordered by bug group > region > index and creation date
        const entityContext = project.getContext();
        const q = query(entityContext, "Bug")
            .predicateWithFormat("map.project == {project}", { project })
            .predicateWithFormat("bugGroup IN {bugGroups}", { bugGroups })
            .orderBy("bugGroup.id, map.region.id, index desc, creationDate desc");
        const allBugsByBugGroupsAndRegions = q.execute().sortedEntities();

        // Then we group bugs to process by bug group and region
        const bugsGroupedByBugGroup: { [bugGroup: string]: { [region: string]: BugEntity[]} } = {};
        bugs.map(bug => {
            const bugGroupId = bug?.bugGroup?.id ?? CONST_UNASSIGNED_ID;
            const regionId = bug.map?.region?.id ?? CONST_UNASSIGNED_ID;

            if (bugsGroupedByBugGroup[bugGroupId] === undefined) {
                bugsGroupedByBugGroup[bugGroupId] = {};
            }
            if (bugsGroupedByBugGroup[bugGroupId][regionId] === undefined) {
                bugsGroupedByBugGroup[bugGroupId][regionId] = [];
            }
            bugsGroupedByBugGroup[bugGroupId][regionId].push(bug);
        });
        

        // Then we iterate over all bugs by bug group and region to find the reference bug
        const referenceBugs: [BugEntity, BugEntity | undefined][] = [];
        let actualBugGroupId = null;
        let actualRegionId = null;
        for (const referenceBug of allBugsByBugGroupsAndRegions) {
            const bugRegionId = referenceBug.map.region?.id ?? CONST_UNASSIGNED_ID;
            const bugGroupId = referenceBug.bugGroup?.id ?? CONST_UNASSIGNED_ID;
            if (bugRegionId == actualRegionId && bugGroupId == actualBugGroupId) {
                // Skip until the next region to process
                continue;
            }
            if (actualBugGroupId !== bugGroupId) {
                actualBugGroupId = bugGroupId;
            }
            actualRegionId = bugRegionId;
            const actualBugsToProcess = bugsGroupedByBugGroup[bugGroupId][actualRegionId];
            actualBugsToProcess?.map(bugToProcess => 
                referenceBugs.push([bugToProcess, referenceBug]),
            );
            bugsGroupedByBugGroup[bugGroupId][actualRegionId] = []; // Remove processed bugs
        }
            // Finally we add bugs without bugReference
            Object.entries(bugsGroupedByBugGroup).map(([_bugGroupId, bugByRegions]) => {
                Object.entries(bugByRegions).map(([_regionId, bugs]) => {
                    bugs.map(bug => referenceBugs.push([bug, null]));
                });
            });

        return referenceBugs;
    }

    private makeBugQuery(options: { where: BugWhereInput; orderBy?: string }): Query<BugEntity> {
        const { where, orderBy } = options;
        const { project, bugGroups, includeTempBugs, maps, visits, phases } = where;
        const entityContext = project.getContext();

        const q = query(entityContext, "Bug")
            .predicateWithFormat("map.project == {project}", { project })
            .orderBy(formatTextList([orderBy, "id asc"], ", "));

        if (!includeTempBugs) {
            q.predicateWithFormat("temp == false");
        }

        if (bugGroups) {
            q.predicateWithFormat("bugGroup IN {bugGroups}", { bugGroups });
        }

        if (maps) {
            q.predicateWithFormat("map IN {maps}", { maps: maps });
        }

        if (visits) {
            q.predicateWithFormat("visit IN {visits}", { visits: visits });
        }

        if (phases !== undefined) {
            q.predicateWithFormat("phase IN {phases}", { phases });
        }

        return q;
    }

    //#endregion

    //#region BugNote

    public updateBugNote(bugNote: BugNoteEntity, payload: UpdateBugNotePayload) {
        const parsedPayload = UpdateBugNotePayload.parse(payload);
        updateEntityWithProperties(bugNote, parsedPayload);
    }

    public findLastBugNoteConcerningBugPhase(bug: BugEntity): BugNoteEntity | null {
        const bugNotes = this.fetchSortedBugNotes(bug);
        const bugNotesConcerningPhase =
            bugNotes.filter(bugNote => bugNote.phase);
        const lastBugNoteConcerningPhase = bugNotesConcerningPhase.pop();
        return lastBugNoteConcerningPhase;
    }

    public fetchSortedBugNotes(bug: BugEntity): BugNoteEntity[] {
        const entityContext = bug.getContext();
        const q = query(entityContext, "BugNote");
        q.predicateWithFormat("bug == {bug}");
        q.orderBy('creationDate');
        const predicateParams = { 'bug': bug };
        const resultSet = q.execute(predicateParams);
        const bugNotes = resultSet.sortedEntities();
        return bugNotes;
    }

    //#endregion

    //#region Transition

    public findExplicitSystemTransition(startState: BugStateEntity, endState: BugStateEntity, project: ProjectEntity): BugTransitionEntity | null {
        const entityContext = project.getContext();
        const queryBugTransition = query<BugTransitionEntity>(entityContext, "BugTransition");
        queryBugTransition.predicateWithFormat("startState == {startState} AND endState == {endState} AND autoActivation == {autoActivation}");
        const predicateParams = { 'startState': startState, 'endState': endState, 'autoActivation': BugTransitionActivation.TransitionActivationExplicitSystemTransition };
        const resultSet = queryBugTransition.execute(predicateParams);
        const count = resultSet.count();
        if (count == 0) {
            return null;
        }
        if (count > 1) {
            this.logger.warn("more than 1 match in find transition");
        }
        const bugTransition = resultSet.firstEntity();
        return bugTransition;
    }

    //#endregion
}

export interface BugWhereInput {
    project: ProjectEntity;
    bugGroups?: ReadonlyArray<null | BugGroupEntity>;
    includeTempBugs?: boolean;
    maps?: readonly MapEntity[];
    visits?: readonly VisitEntity[];
    phases?: ReadonlyArray<PhaseEntity | null>;
}

const BugSchema = z
    .object({
        visit: z.instanceof(VisitEntity),
        map: z.instanceof(MapEntity),
        phase: z.instanceof(PhaseEntity).nullish(),
    })
    .passthrough();

type BugSchema = z.infer<typeof BugSchema>;

export type CreateBugPayload = BugSchema & CreateEntityPayload<BugEntity>;

export const UpdateBugNotePayload = z.object({
    text: z.string().nullable().optional(),
});

export type UpdateBugNotePayload = z.infer<typeof UpdateBugNotePayload>;
