import * as t from 'io-ts';
import { EntityIdCodec, NumericStringCodec, DateFormatCodec, TimeFormatCodec, StringNameBoolValueArray, StringNameValueArray } from '@core/iots/codecs';
import { knownPaperSizeInMM } from '@archipad/helpers/paperSizeHelper';

const PreferedLanguages = t.keyof({
    fr: null,
    en: null,
    es: null,
    de: null
});
export type PreferedLanguages = t.TypeOf<typeof PreferedLanguages>;

export const BackgroundOrientationV = t.union([
    t.literal(0),
    t.literal(90),
    t.literal(180),
    t.literal(270)
]);

const ColumnNames = t.keyof({
    bug: null,
    people: null,
    remark: null
});
export type ColumnNames = t.TypeOf<typeof ColumnNames>;

const GroupBy = t.keyof({
    wps: null,
    maps: null,
    locs: null
});
export type GroupBy = t.TypeOf<typeof GroupBy>;

const PhotosSize = t.keyof({
    small: null,
    medium: null,
    large: null
});
export type PhotosSize = t.TypeOf<typeof PhotosSize>;

const Observations = t.keyof({
    all: null,
    open: null,
    visit: null,
    open_and_visit: null
});
export type Observations = t.TypeOf<typeof Observations>;

const InsertExtra = t.keyof({
    history_comments: null,
    comments: null,
    none: null
});
export type InsertExtra = t.TypeOf<typeof InsertExtra>;

const Remarks = t.keyof({
    all: null,
    open: null,
    visit: null,
    open_and_visit: null
});
export type Remarks = t.TypeOf<typeof Remarks>;

const DotColorFunction = t.keyof({
    monochrome: null,
    time_based: null,
    wps: null
});
export type DotColorFunction = t.TypeOf<typeof DotColorFunction>;

const RStyle = t.keyof({
    modern: null,
    pyjama: null,
    simple: null,
    // custom for future-proof
    custom: null
});
export type RStyle = t.TypeOf<typeof RStyle>;

const ExtendedColumnWidth = t.partial({
    portrait: t.number,
    landscape: t.number,
    min: t.number
});
export type ExtendedColumnWidth = t.TypeOf<typeof ExtendedColumnWidth>;

const ColumnDescription = t.partial({
    width: t.union([ExtendedColumnWidth, t.number]),
    title: t.string,
    class: t.string,
    virtual: t.boolean,
    expanded: t.boolean
})
export type ColumnDescription = t.TypeOf<typeof ColumnDescription>;

const FieldClass = t.partial({
    class: t.string,
    content: t.string,
    type: t.string,
    dependencies: t.array(t.string)
});
export type FieldClass = t.TypeOf<typeof FieldClass>;

const FieldDescription = t.interface({
    type: t.string,
    content: t.string,
    dependencies: t.array(t.string)
});
export type FieldDescription = t.TypeOf<typeof FieldDescription>;

const Column = t.intersection([
    t.type({
        id: t.string,
        label: t.string,
        column: ColumnDescription
    }),
    t.partial({
        cell: FieldClass,
        mandatory: t.boolean,
        field: FieldDescription
    })
]);
export type Column = t.TypeOf<typeof Column>;

const ColumnsDefinition = t.interface({
    name: ColumnNames,
    columns: t.array(Column)
});
export type ColumnsDefinition = t.TypeOf<typeof ColumnsDefinition>;

const ReportColumnsDefinitions = t.record(ColumnNames, ColumnsDefinition);
export type ReportColumnsDefinitions = t.TypeOf<typeof ReportColumnsDefinitions>;

const PageDefinition = t.interface({
    pageBreakBefore: t.boolean,
})
export type PageDefinition = t.TypeOf<typeof PageDefinition>;

const PlanningPaperSize = t.keyof(knownPaperSizeInMM);
export type PlanningPaperSizeDefinition = t.TypeOf<typeof PlanningPaperSize>;

const PlanningOrientation = t.keyof({
    'landscape': null,
    'portrait': null,
});
export type PlanningOrientation = t.TypeOf<typeof PlanningOrientation>;

export const IncludeMaps = t.keyof({
    'before_related_bugs' : null,
    'after_related_bugs' : null,
    'include_maps' : null,
    'maps_as_annexe' : null,
});

const ReportParameters = t.intersection([
    t.type({
        pages: StringNameValueArray(PageDefinition),
        bug_cols: StringNameBoolValueArray,
        people_cols: StringNameBoolValueArray,
        remark_cols: StringNameBoolValueArray,
        category_fields: StringNameBoolValueArray,
        columns_definition: ReportColumnsDefinitions,
        group_by: GroupBy,
        page_break: t.boolean,
        rstyle: RStyle,
        condensed: t.boolean,
        order_by: t.array(t.string),
        photos_size: PhotosSize,
        observations_title: t.string,
        filename: t.string,
        orientation: t.string,
        paper_size: t.string,
        "tint-color": t.unknown.pipe(NumericStringCodec),
        observations_filters_by_states: t.UnknownRecord,
        remarks_title: t.string,
        insert_extra: InsertExtra,
        remarks: Remarks,
        dot_color_function: DotColorFunction,
        include_maps: IncludeMaps,
        project: t.string.pipe(EntityIdCodec),
        visit: t.string.pipe(EntityIdCodec),
        identifier: t.string,
        planning_paper_size: PlanningPaperSize,
        planning_orientation: PlanningOrientation,
        planning_display: t.boolean,
    }),
    t.partial({
        excel: t.boolean,
        region: t.string.pipe(EntityIdCodec),
        wps: t.array(t.string),
        maps: t.array(t.string),
        phases: t.array(t.string),
        checklists: t.union([t.array(t.string), t.null]),
        includeMapsWithoutBugs: t.boolean,
        useVisitPhase: t.boolean,
    }),
]);
export type ReportParameters = t.TypeOf<typeof ReportParameters>;

const ReportConfigData = t.interface({
    dateFormat: t.string.pipe(DateFormatCodec),
    hourFormat: t.string.pipe(TimeFormatCodec),
    report_parameters: ReportParameters,
    backgroundOrientation: BackgroundOrientationV
});
export type ReportConfigData = t.TypeOf<typeof ReportConfigData>;

export const ReportConfig = t.exact(t.interface({
    data: ReportConfigData,
    preferedLanguages: t.array(t.string)
}));
export type ReportConfig = t.TypeOf<typeof ReportConfig> & { debug? : Record<string,any>};