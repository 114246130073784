import { Logger } from "@archipad-js/core/logger";
import { BugEntity } from "@archipad-models/models/BugEntity";
import { BugGroupEntity } from "@archipad-models/models/BugGroupEntity";
import { BugStateEntity } from "@archipad-models/models/BugStateEntity";
import { BugTransitionEntity } from "@archipad-models/models/BugTransitionEntity";
import { ProjectEntity } from "@archipad-models/models/ProjectEntity";
import { EquivBugState, EquivBugTransition } from "@archipad/backend/project/workflowManagerConstant";
import { createLogger } from "@core/services/logger.service";
import { query, ResultSet } from "@core/services/orm/query";
import { isBugWorkflowEnabled } from '@archipad/backend/project/workflowManagerHelper';

/**
 * Low level service to manage the workflow
 */
export class WorkflowService {

    /**
     * Constructor of the WorkflowService
     * Keep low level services, don't use high level managers (ex: WorkflowUserManager)
     */
    constructor(
        protected readonly logger: Logger = createLogger("WorkflowService"),
    ) { }


    findEquivBugState(equivState: EquivBugState, project: ProjectEntity): BugStateEntity {
        const entityContext = project.getContext();
        const bugStateQuery = query<BugStateEntity>(entityContext, "BugState");
        bugStateQuery.predicateWithFormat("equivBugState == {equivBugState}");
        const predicateParams = { 'equivBugState': equivState };

        const resultSet = bugStateQuery.execute(predicateParams);
        const count = resultSet.count();

        if (count != 1) {
            return null;
        }

        const bugState = resultSet.firstEntity();
        return bugState;
    }

    findEquivTransition(equivTransition: EquivBugTransition, bugState: BugStateEntity, project: ProjectEntity): BugTransitionEntity {
        const entityContext = project.getContext();
        const queryBugTransition = query<BugTransitionEntity>(entityContext, "BugTransition");
        queryBugTransition.predicateWithFormat("equivBugTransition == {equivBugTransition} AND endState == {endState}");
        const predicateParams = { 'equivBugTransition': equivTransition, 'endState': bugState };

        const resultSet = queryBugTransition.execute(predicateParams);
        const count = resultSet.count();

        // get the 1st match
        if (count == 0) {
            return null;
        }
        if (count > 1) {
            this.logger.warn("more than 1 match in find transition");
        }
        const bugTransition = resultSet.firstEntity();

        return bugTransition;
    }

    isKindOfClosed(bugState:BugStateEntity) : boolean {
        const equivBugState: EquivBugState = bugState.equivBugState;
        return equivBugState == EquivBugState.EquivStateClosed
            || equivBugState == EquivBugState.EquivStateClosedAlt
            || equivBugState == EquivBugState.EquivStateClosedRejected;
    }

    isKindOfOpen(bugState:BugStateEntity) : boolean {
        const equivBugState: EquivBugState = bugState.equivBugState;
        return equivBugState == EquivBugState.EquivStateOpen
            || equivBugState == EquivBugState.EquivStateOpenAlt;
    }

        /**
     * Checks whether the given bug state is awaiting for approval or not.
     *
     * As of now, the only known state that means "awaiting for approval" is the
     * open state of the delivery group.
     *
     * The open state of the default group is considered as approved by default
     * and has to be explicitly rejected if not which is not the case for the
     * delivery group.
     */
    isKindOfAwaitingForApproval(bugState: BugStateEntity): boolean {
        const isKindOfOpen = this.isKindOfOpen(bugState);
        const isDeliveryGroup = bugState.bugGroup === this.bugGroupToValidateForProject(bugState.bugWorkflow.project);
        const isKindOfAwaitingForApproval = isKindOfOpen && isDeliveryGroup;
        return isKindOfAwaitingForApproval;
    }

    kindOfOpenArray() : EquivBugState[] {
        return [
            EquivBugState.EquivStateOpen,
            EquivBugState.EquivStateOpenAlt,
        ];
    }

    bugsToValidateForProject(project: ProjectEntity): BugEntity[] {
        const bugGroup = this.bugGroupToValidateForProject(project);
        const entityContext = project.getContext();
        const q = query(entityContext, "Bug");
        q.predicateWithFormat("stateNoteCache.bugState.equivBugState == {equivStateOpen} OR stateNoteCache.bugState.equivBugState == {equivStateOpenAlt}", 
        { 'equivStateOpen': EquivBugState.EquivStateOpen , 'equivStateOpenAlt' : EquivBugState.EquivStateOpenAlt });
        q.predicateWithFormat("bugGroup == {bugGroup}", { 'bugGroup': bugGroup });
        q.orderBy('index');
        const bugs: ResultSet<BugEntity> = q.execute();
        return bugs.sortedEntities();
    }

    bugGroupToValidateForProject(project: ProjectEntity): BugGroupEntity {
        if (!isBugWorkflowEnabled(project)) {
            return null;
        }
        if (!project.bugWorkflow?.bugGroups || project.bugWorkflow.bugGroups.length == 0) {
            return null;
        }
        const entityContext = project.getContext();
        const q = query(entityContext, "BugGroup");
        q.orderBy('name asc');
        return q.execute().firstEntity();
    }

    /**
    * Returns a sorted list of {@link BugStateEntity}.
    * 
    * Use {@link BugStateEntity.orderHint} to sort states. If every states of
    * the given {@link BugGroupEntity} do NOT have orderHint, it fallbacks on
    * an automatic traversal algorithm on {@link BugTransitionEntity}.
    * 
    * @param bugGroup The {@link BugGroupEntity} to filter states
    */
    listStates(project: ProjectEntity, bugGroup: BugGroupEntity | null | undefined): readonly BugStateEntity[] {
        const entityContext = project.getContext();
    
        const q = query(entityContext, "BugState");
        if (bugGroup !== undefined){
            q.predicateWithFormat("bugGroup == {bugGroup}", { bugGroup });
        }
        q.orderBy("orderHint asc, id asc");
    
        const resultSet = q.execute();
        const states = resultSet.sortedEntities();
    
        const hasOrderHint = states.every((state) => state.orderHint !== 0);
        if (!hasOrderHint) {
            return this.listStatesAuto(project, bugGroup);
        }
    
        return states;
    }
    
    /**
     * List all {@link BugStateEntity} of given {@link BugGroupEntity} using an
     * automatic traversal algorithm on {@link BugTransitionEntity}.
     *
     * It walks through all enter transitions (i.e: null => open) and list all
     * transitions leading to those states (i.e: open => solved), then again
     * list all transitions leading to those states (i.e: solved => closed)
     * and so on until all states have been walked.
     */
    private listStatesAuto(project: ProjectEntity, bugGroup: BugGroupEntity | null | undefined): readonly BugStateEntity[] {
        const entityContext = project.getContext();
    
        const q = query(entityContext, "BugTransition");
        if (bugGroup !== undefined){
            q.predicateWithFormat("endState.bugGroup == {bugGroup}", { bugGroup });
        }
        q.predicateWithFormat("equivBugTransition == {equivTransitionEnter}", {
            bugGroup,
            equivTransitionEnter: EquivBugTransition.EquivTransitionEnter,
        });
        q.orderBy("id");
    
        const resultSet = q.execute();
        const enterTransitions = resultSet.sortedEntities();
    
        const sortedStates = [];
    
        for (const enterTransition of enterTransitions) {
            this.listStatesTraversal(sortedStates, enterTransition);
        }
    
        return sortedStates;
    }
    
    /**
     * Traverse the given {@link BugTransitionEntity transition} to find all
     * related states.
     *
     * @see {@link listStatesAuto}
     *
     * @param sortedStates List of sorted {@link BugStateEntity states} already listed, used to avoid duplication and infinite recursion
     * @param enterTransition The enter {@link BugTransitionEntity transition} to walk through
     */
    private listStatesTraversal(sortedStates: BugStateEntity[], enterTransition: BugTransitionEntity): void {
        const state = enterTransition.endState;
    
        sortedStates.push(state);
    
        /**
         * Sort transition by {@link BugTransition.equivBugTransition}
         *
         * 1. Enter transition
         * 2. Reject transition
         * 3. Accept transition
         * 4. None transition
         */
        const transitions = state.bugTransitionStarts.slice().sort((transitionA, transitionB) => {
            const diff = transitionB.equivBugTransition - transitionA.equivBugTransition;
    
            if (diff !== 0) {
                // can sort by transition
                return diff;
            }
    
            const isCloseStateA = this.isKindOfClosed(transitionA.endState);
            const isCloseStateB = this.isKindOfClosed(transitionB.endState);
    
            if (isCloseStateA === isCloseStateB) {
                // both transitions end in a final state, do nothing
                return 0;
            }
    
            if (isCloseStateA) {
                // transition A ends in final state, not B: sort A after B
                return 1;
            }
    
            // transition B ends in final state, not A: sort A before B
            return -1;
        });
    
        for (const transition of transitions) {
            if (sortedStates.includes(transition.endState)) {
                continue;
            }
    
            this.listStatesTraversal(sortedStates, transition);
        }
    }

}

export default new WorkflowService();